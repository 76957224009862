<template>
    <section class="page-container">
        <div class="image-403"></div>
        <div class="title">{{ $t('noPermission') }}</div>
    </section>
</template>

<style lang="less" scoped>
.page-container {
    padding: 16%;
    .image-403 {
        height: 240px;
        background: url(../../assets/img/no_permission.png) no-repeat center;
    }
    .title {
        text-align: center;
        color: #333333;
    }
}
</style>
